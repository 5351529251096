import axios, { AxiosHeaders } from 'axios'
import Analysis, { AnalysisImageMetadata } from '../models/analysis'
import ContinuousAnalysis from '../models/continuous-analysis'
import DCSTag from '../models/dcs-tag'
import ImagesConfig from '../models/imagesConfig'
import Site from '../models/site'
import { TIMEFRAME_BY_TIMESTAMP } from '../store/actions/data-actions'
import axiosAvaApiClient from '../utils/axiosAvaApiClient'
import StatusCodes from '../utils/StatusCodes'
import ChunkedFileUploader from '../models/chunked-file-uploader'

export default class DataService {


   /*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
   |                        SITES                       |
   \*__________________________________________________*/

   /**
    * Get sites
    * @returns {Promise<Site[]>}
    */
   static async getSites() {
      const response = await axiosAvaApiClient.get('/sites')
      const data = response.data
      const sites = data.map((site) => new Site(site.id, site.name, site.timeZone)
      )
      return sites
   }

   /**
    * Get site with data
    * @param {string} siteId
    * @returns {Promise<Record<any, any>>}
    */
   static async getSite(siteId) {
      try {
         const response = await axiosAvaApiClient.get(`/sites/${siteId}`)
         return response.data
      } catch(err) {
         console.error(`Error loading site data: ${err}`)
         throw err
      }
   }


   static async editSiteTimezone(siteId, timeZone) {
      try {
         const response = await axiosAvaApiClient.patch(`/sites/${siteId}`, { timeZone })
         return response.data
      } catch(err) {
         const error = (err.response ? err.response.data : err)
         console.error(error)
         throw `Error editing timezone: ${error}`
      }
   }



   /*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
   |                      ANALYSES                      |
   \*__________________________________________________*/

   /**
    * Get analyses options
    * @typedef {object} GetAnalysesOptions
    * @property {string} tool
    * @property {Date} dateFrom
    * @property {Date} dateTo
    * @property {string=} site
    * @property {string[]=} units
    * @property {string[]=} positions
    * @property {string=} author
    * @property {string[]=} tags
    * @property {("timestamp"|"dateAnalyzed")=} queryDateBy
    * @property {boolean} [onlyWithImages=false]
    * @property {number} [limit=100000]
    * @property {object} [advancedFilters]
    * @property {boolean} [advancedFilters.errors]
    * @property {boolean} [advancedFilters.exceptions]
    */
   /**
    * Get analyses from api
    * @param {GetAnalysesOptions} options
    * @returns {{ promise: Promise<Analysis[]>, cancel: () => void }}
    */
   static getAnalyses(options) {
      const { tool, dateFrom, dateTo, site, units, positions, author, tags, queryDateBy = TIMEFRAME_BY_TIMESTAMP, onlyWithImages = false, limit = 100000, advancedFilters } = options
      const params = {
         site,
         units,
         positions,
         author,
         onlyWithImages,
         tags,
         advancedFilters,
      }

      if (queryDateBy === TIMEFRAME_BY_TIMESTAMP) {
         params.timestampFrom = dateFrom
         params.timestampTo = dateTo
      } else {
         params.dateAnalyzedFrom = dateFrom
         params.dateAnalyzedTo = dateTo
      }
      if (limit) params.limit = limit

      const controller = new AbortController()
      const promise = new Promise((resolve, reject) => {
         axiosAvaApiClient
            .get(`/analyses/${tool}`, {
               signal: controller.signal,
               maxContentLength: 1024 * 1024,
               params,
            })
            .then((response) => {
               const analyses = Analysis.fromJsonArray(response.data).reverse()
               resolve(analyses)
            })
            .catch((err) => {
               if (axios.isCancel(err)) return // Request was cancelled
               console.error(`Failed to get data: ${err}`)
               reject(`Failed to get data: ${err}`)
            })
      })
      return {
         cancel: (reason) => controller.abort(reason),
         promise,
      }
   }

   /**
    * @param {object} images { <imageId>: <boolean> }
    * @returns {Promise<Object.<string, AnalysisImageMetadata>} { <imageId>: AnalysisImageMetadata, ... }
    */
   static keepImages(images) {
      return new Promise((resolve, reject) => {
         axiosAvaApiClient.post('/images/keep', images)
            .then((result) => {
               const imageMetadatas = {}
               for (const item of result.data) {
                  imageMetadatas[item.imageId] = AnalysisImageMetadata.fromJson(item)
               }
               resolve(imageMetadatas)
            })
            .catch((err) => reject(err))
      })
   }

   /**
    * @param {object} options
    * @param {string} options.toolId
    * @param {string} options.siteId
    * @param {string} options.unitId
    * @param {string} options.comment
    * @param {string[]} options.tags
    * @param {string} options.socketId
    * @param {{ dateTaken: Date, position: string }[]} options.fileDetails
    * @returns {Promise<{ id: string, minChunkSize?: number }>}
    */
   static async createAnalysis(options) { // TODO: add optional timestamp parameter
      const { toolId, siteId, unitId, comment, tags, socketId, fileDetails } = options

      // Create FormData and add parameters
      const data = new FormData()
      data.append('site', siteId)
      data.append('unit', unitId)
      data.append('socketId', socketId)
      if (comment) data.append('comment', comment)
      // if (timestamp) data.append('timestamp', timestamp)

      // Set tags
      for (const i in tags) {
         data.append(`tags[${i}]`, tags[i])
      }


      // Set file dates and positions
      for (const i in fileDetails) {
         const details = fileDetails[i]
         data.append(`fileDetails[${i}][dateTaken]`, details.dateTaken.toISOString())
         data.append(`fileDetails[${i}][position]`, details.position)
      }

      const response = await axiosAvaApiClient
         .postForm(`/analyses/${toolId}`, data, {
            headers: { 'Accept-Version': 1 },
         })

      return response.data
   }

   /**
    * @param {object} options
    * @param {string} options.analysisId
    * @param {string} options.toolId
    * @param {string} options.positionId
    * @param {Date} options.dateTaken
    * @param {Blob} options.file
    * @param {string} options.fileName
    * @param {number} [options.continueFromFileChunkIndex] If upload is cancelled or fails some other way you can still continue the upload by providing chunk index where to continue the upload
    * @param {string} options.socketId
    * @param {(progress: { status: ("uploading"|"analyzing"), value: number | null, fileChunkIndex?: number }) => void} options.onProgress value: `"percent completed"`
    * @param {object} [options.chunkedUpload]
    * @param {boolean} options.chunkedUpload.enabled Upload files in smaller chunks. Note that old API versions don't support this.
    * @param {number} [options.chunkedUpload.minChunkSize]
    * @param {() => void} options.onCompleted
    * @param {(err: any) => void} options.onFailure
    * @returns {{ cancel: () => void }}
    */
   static uploadAnalysisFile(options) { // TODO: add optional timestamp parameter
      const { analysisId, toolId, positionId, dateTaken, file, fileName, continueFromFileChunkIndex, socketId, chunkedUpload, onCompleted, onFailure, onProgress } = options

      // Multipart file upload
      if (chunkedUpload?.enabled) {
         const uploader = new ChunkedFileUploader({
            analysisId,
            toolId,
            positionId,
            chunkSize: chunkedUpload.minChunkSize,
            onCompleted,
            onFailure,
            file,
            fileName,
            socketId,
            dateTaken,
            onUploadProgress: (value, status, fileChunkIndex) => {
               onProgress({
                  status,
                  value: Math.round(value * 100),
                  fileChunkIndex,
               })
            },
         })
         const { cancel } = uploader.upload({ fromChunkIndex: continueFromFileChunkIndex || 0 })
         return {
            cancel: () => {
               cancel()
               onFailure(new Error('Cancelled'))
            },
         }
      }

      // Upload the whole file
      const onUploadProgress = (progressEvent) => {
         const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
         onProgress({
            status: (progressEvent.loaded === progressEvent.total) ? 'analyzing' : 'uploading',
            value: percentCompleted,
         })
      }

      const controller = new AbortController()
      const headers = { 'Content-Type': file.type }
      if (fileName) headers['X-AVA-FileName'] = fileName.split(/[\\/]/).pop()
      if (socketId) headers['X-AVA-socketId'] = socketId
      headers['X-AVA-DateTaken'] = dateTaken.toISOString()

      axiosAvaApiClient
         .put(`/analyses/${toolId}/${analysisId}/${positionId}/original`, file, {
            signal: controller.signal,
            onUploadProgress,
            headers,
         })
         .then(() => onCompleted())
         .catch((err) => {
            if (axios.isCancel(err)) { // Request was cancelled
               onFailure(new Error('Cancelled'))
               return
            }
            console.error('Failed to send file to analysis', err)
            onFailure(err)
         })


      return {
         cancel: (reason) => controller.abort(reason),
      }
   }

   static deleteAnalysis(id, tool) {
      return new Promise((resolve, reject) => {
         axiosAvaApiClient.delete(`/analyses/${tool}/${id}`)
            .then(resolve)
            .catch((err) => {
               console.error(`Failed to delete analysis: ${err}`)
               reject(err)
            })
      })
   }

   static editAnalysis(id, comment, tags, unit, tool) {
      return new Promise((resolve, reject) => {
         const params = {
            unit,
            tags,
            comment,
         }
         axiosAvaApiClient.put(`/analyses/${tool}/${id}`, params)
            .then((result) => {
               resolve(result.data)
            })
            .catch((err) => {
               console.error(`Failed editing: ${err}`)
               reject(err)
            })
      })
   }

   /**
    * Returns URL for downloads image files archive
    * `NOTE:` Use downloadImagesArchive for downloading lots of images
    * @param {[string]} imageIds
    * @returns {string} - Download URL
    */
   static getDownloadImagesURL(imageIds) {
      return `${process.env.PUBLIC_URL}/api/images?${imageIds.map((i) => `images[]=${i}`).join('&')}`
   }

   /**
    * Downloads image files archive
    * @param {[string]} imageIds
    */
   static downloadImagesArchive(imageIds) {
      const form = document.createElement('form')
      form.target = '_blank'
      form.method = 'POST'
      form.action = `${process.env.PUBLIC_URL}/api/images`
      form.style.display = 'none'

      for (const imageId of imageIds) {
         const input = document.createElement('input')
         input.type = 'hidden'
         input.name = 'images[]'
         input.value = imageId
         form.appendChild(input)
      }

      document.body.appendChild(form)
      form.submit()
      document.body.removeChild(form)
   }


   /*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
   |                 CONTINUOUS ANALYSES                |
   \*__________________________________________________*/

   /**
    * @param {string} site
    * @param {string} tool
    * @returns {Promise<{ continuousAnalyses: ContinuousAnalysis[], positionStates: object }>}
    */
   static getContinuousAnalyses(site, tool) {
      return new Promise((resolve, reject) => {
         const params = { site, tool }

         axiosAvaApiClient.get('/continuousAnalyses', { params })
            .then((response) => {
               if (!response.data) return resolve({ continuousAnalyses: [], positionStates: {} })

               const continuousAnalyses = ContinuousAnalysis.fromJsonArray(response.data)
               const positionStates = response.data.reduce((prevVal, val) => ({
                  ...prevVal,
                  [val.position]: {
                     ...val.state,
                     stopMetadata: val.stopMetadata,
                  },
               }), {})

               resolve({
                  continuousAnalyses,
                  positionStates,
               })
            })
            .catch((err) => reject(err))
      })
   }

   // FIXME Should use the continuous analysis ID PUT /continuousAnalyses/{id} and body the configuration
   static setContinuousAnalysis(site, tool, unit, position, parameters) {
      return new Promise((resolve, reject) => {
         axiosAvaApiClient.put(`/continuousAnalyses`, { site, tool, unit, position, parameters })
            .then((result) => {
               resolve(result.data)
            })
            .catch((err) => {
               console.error('Failed to edit ', err)
               reject(
                  (err.response || {}).data
                  || err.message
                  || 'Failed to edit analysis parameters'
               )
            })
      })
   }

   static startOrStopContinuousAnalysis(id, start, reason) {
      return new Promise((resolve, reject) => {
         let body = null
         if (start === false) body = { reason } // Provide reason when stopping analysis

         axiosAvaApiClient.post(`/continuousAnalyses/${id}/${start ? 'start' : 'stop'}`, body)
            .then((result) => {
               resolve(result.data)
            })
            .catch((err) => {
               console.error(`Failed editing: ${err}`)
               reject(err)
            })
      })
   }


   /*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
   |                     STATISTICS                     |
   \*__________________________________________________*/

   static getStatistics(siteId) {
      return new Promise((resolve, reject) => {
         const params = { siteId }
         axiosAvaApiClient.get('/statistics', { params })
            .then((response) => {
               resolve(response.data)
            })
            .catch((err) => {
               console.error('Failed to get site statistics:', err)
               reject(err)
            })
      })
   }


   /*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
   |                        TAGS                        |
   \*__________________________________________________*/

   static createTag(name, tool, site) {
      return new Promise((resolve, reject) => {
         const params = {
            site,
            tool,
            name,
         }
         axiosAvaApiClient.post(`/tags`, params)
            .then((response) => {
               resolve(response.data)
            })
            .catch((err) => {
               switch (err.response.status) {
                  case StatusCodes.CONFLICT:
                     reject(err.response.data)
                     break
                  default:
                     console.error(`Failed to create tag: ${err}`)
                     reject('Failed to create tag')
               }
            })
      })
   }

   static deleteTag(tagId) {
      return new Promise((resolve, reject) => {
         axiosAvaApiClient.delete(`/tags/${tagId}`)
            .then(resolve)
            .catch((err) => {
               console.error(`Failed to delete tag: ${err}`)
               reject(err)
            })
      })
   }

   static renameTag(id, name) {
      return new Promise((resolve, reject) => {
         const params = {
            name,
         }
         axiosAvaApiClient.put(`/tags/${id}`, params)
            .then(resolve)
            .catch((err) => {
               console.error(`Editing failed: ${err}`)
               reject(err)
            })
      })
   }


   /*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
   |                        UNITS                       |
   \*__________________________________________________*/

   static createUnit(name, tools, site) {
      return new Promise((resolve, reject) => {
         const params = {
            site,
            tools,
            name,
         }
         axiosAvaApiClient.post(`/units`, params)
            .then((result) => {
               resolve(result.data)
            })
            .catch((err) => {
               console.error(`Failed to create unit: ${err}`)
               reject(err)
            })
      })
   }

   static deleteUnit(unitId) {
      return new Promise((resolve, reject) => {
         axiosAvaApiClient.delete(`/units/${unitId}`)
            .then(resolve)
            .catch((err) => {
               console.error(`Failed to delete unit: ${err}`)
               reject(err)
            })
      })
   }

   static editUnit(id, name, tools) {
      return new Promise((resolve, reject) => {
         const params = {
            tools,
            name,
         }
         axiosAvaApiClient.put(`/units/${id}`, params)
            .then(resolve)
            .catch((err) => {
               console.error(`Editing failed: ${err}`)
               reject(err)
            })
      })
   }


   /*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
   |                      POSITIONS                     |
   \*__________________________________________________*/

   static createPosition(name, unit, tool, site) {
      return new Promise((resolve, reject) => {
         const params = {
            site,
            tool,
            name,
            unit,
         }
         axiosAvaApiClient.post(`/positions`, params)
            .then((response) => {
               resolve(response.data)
            })
            .catch((err) => {
               switch (err.response.status) {
                  case StatusCodes.CONFLICT:
                     reject(err.response.data)
                     break
                  default:
                     console.error(`Failed to create position: ${err}`)
                     reject('Failed to create position')
               }
            })
      })
   }

   static editPosition(id, name) {
      return new Promise((resolve, reject) => {
         const params = {
            name,
         }
         axiosAvaApiClient.put(`/positions/${id}`, params)
            .then(resolve)
            .catch((err) => {
               console.error(`Editing failed: ${err}`)
               reject(err)
            })
      })
   }

   static deletePosition(id) {
      return new Promise((resolve, reject) => {
         axiosAvaApiClient.delete(`/positions/${id}`)
            .then(resolve)
            .catch((err) => {
               console.error(`Failed to delete position: ${err}`)
               reject(err)
            })
      })
   }


   /*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
   |                      DCS TAGS                      |
   \*__________________________________________________*/

   /**
    * @param {string} site
    * @param {string} tool
    * @param {string=} unit
    * @returns {Promise<[DCSTag]>}
    */
   static getDCSTags(site, tool, unit) {
      return new Promise((resolve, reject) => {
         const params = {
            site, tool,
         }
         if (unit) params.unit = unit

         axiosAvaApiClient.get(`/dcsTags`, { params })
            .then((response) => {
               const dcsTags = response.data.map(DCSTag.fromJson)
               resolve(dcsTags)
            })
            .catch((err) => {
               console.error('Failed to edit DCS Tags: ', err)
               reject('Failed to get DCS Tags')
            })
      })
   }

   /**
    * @param {string} site
    * @param {string} tool
    * @param {{ value: string, position: string, element: string }[]} dcsTags
    * @returns {Promise<[DCSTag]>}
    */
   static editDCSTags(site, tool, dcsTags) {
      return new Promise((resolve, reject) => {
         const payload = {
            site,
            tool,
            dcsTags,
         }
         axiosAvaApiClient.patch(`/dcsTags`, payload)
            .then((response) => {
               const dcsTags = response.data.map(DCSTag.fromJson)
               resolve(dcsTags)
            })
            .catch((err) => {
               console.error('Failed to edit DCS Tags: ', err)
               reject('Failed to edit DCS Tags')
            })
      })
   }


   /*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
   |                    IMAGES CONFIG                   |
   \*__________________________________________________*/

   /**
    * @param tool
    * @param unit
    * @returns {Promise<ImagesConfig>}
    */
   static async getImagesConfig(tool, unit) {
      const params = { tool, unit }
      const { data } = await axiosAvaApiClient
         .get(`/imagesConfig`, { params })
         .catch((err) => {
            console.error('Failed to get ImagesConfig: ', err)
            throw new Error('Failed to get images configuration')
         })

      return ImagesConfig.fromJson(data)
   }

   /**
    * @param tool
    * @param {string} unit
    * @param {Object=} clean - Clean images older than X milliseconds. Don't clean at all if "-1"
    * @param {number=} clean.original
    * @param {number=} clean.originalThumbnails
    * @param {number=} clean.analyzed
    * @param {number=} clean.analyzedThumbnails
    */
   static async updateImagesConfig(tool, unit, clean) {
      const payload = { tool, unit, clean }
      const { data } = await axiosAvaApiClient
         .patch(`/imagesConfig`, payload)
         .catch((err) => {
            console.error('Failed update ImagesConfig: ', err)
            throw new Error('Failed updating images configuration')
         })

      return data
   }


   /*‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾*\
   |                    VERSION INFO                    |
   \*__________________________________________________*/

   static async getVersionInfo() {
      try {
         const response = await axiosAvaApiClient.get(`/build`)
         return response.data
      } catch(err) {
         console.error(err)
         throw 'Error getting info'
      }
   }


}
