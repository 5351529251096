import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import analysesReducer from './analyses-reducer'
import authReducer from './auth-reducer'
import continuousAnalysisReducer from './continuous-analysis-reducer'
import CurrentInstanceReducer from './current-instance-reducer'
import deviceReducer from './device-reducer'
import dialogReducer from './dialog-reducer'
import liveUpdateFollowRangeReducer from './live-update-follow-range-reducer'
import liveUpdatePausedReducer from './live-update-paused-reducer'
import liveUpdateReducer from './live-update-reducer'
import ongoingAnalysisReducer from './ongoing-analysis-reducer'
import positionFiltersReducer from './position-filters-reducer'
import positionsReducer from './positions-reducer'
import resultComponentsReducer from './result-components-reducer'
import selectedAnalysesReducer from './selected-analyses-reducer'
import SelectedUnitReducer from './selected-unit-reducer'
import siteReducer from './site-reducer'
import sitesReducer from './sites-reducer'
import tagFiltersReducer from './tag-filters-reducer'
import taglessFilterReducer from './tagless-filter-reducer'
import tagsReducer from './tags-reducer'
import timeframeReducer from './timeframe-reducer'
import timeframeSelectionReducer from './timeframe-selection-reducer'
import timeframeZoomSelectionReducer from './timeframe-zoom-selection-reducer'
import timeZoneReducer from './timezone-reducer'
import toolReducer from './tool-reducer'
import toolboxesReducer from './toolboxes-reducer'
import toolsReducer from './tools-reducer'
import unitFiltersReducer from './unit-filters-reducer'
import unitsReducer from './units-reducer'
import userReducer from './user-reducer'
import userToolConfigReducer from './user-tool-config-reducer'

/**
 * @template T
 * @typedef {import('redux').Reducer<T, ReduxAction<any>>} ReduxReducer
 */
/**
 * @template T
 * @typedef {{ type: string, payload: T }} ReduxAction
 */
/**
 * @template T
 * @typedef {import('redux-thunk').ThunkAction<T, import('./root-reducer').ReduxStoreData, undefined, import('redux').Action>} ReduxThunkAction
 */
/** @typedef {import('redux-thunk').ThunkDispatch<ReduxStoreData, undefined, import('redux').Action>} ReduxDispatch */
/** @typedef {import('react-redux').TypedUseSelectorHook<ReduxStoreData>} ReduxSelector */
/**
 * @typedef {object} ReduxStoreData
 * @property {Object<string, import('../../models').Site>} sites
 * @property {any} device
 * @property {any} timeZone Selected timezone that is used in HMI. Initial timezone is set from site-actions.js.
 * @property {import('./dialog-reducer').Dialog} dialog
 *
 * @property {any} auth
 * @property {any} user
 * @property {any} userToolConfig
 *
 * @property {Object<string, import('../../models').Tool>} tools { <toolId>: Tool }
 * @property {Object<string, import('../../models').Toolbox>} toolboxes
 * @property {Object<string, import('../../models').Unit>} units
 * @property {Object<string, import('../../models').Position>} positions
 * @property {Object<string, import('../../models').Tag>} tags
 * @property {string} selectedSite Site ID
 * @property {string} selectedTool Tool ID
 *
 * @property {Object<string, import('../../models').Analysis[]>} analyses
 * @property {any} selectedAnalyses
 * @property {import('./timeframe-reducer').Timeframe} timeframe
 * @property {import('./live-update-paused-reducer').LiveUpdatePaused} liveUpdatePaused
 * @property {import('./timeframe-zoom-selection-reducer').TimeframeZoomSelection} timeframeZoomSelection
 * @property {import('./timeframe-selection-reducer').TimeframeSelection} timeframeSelection
 * @property {import('./live-update-follow-range-reducer').LiveUpdateFollowRange} liveUpdateFollowRange
 * @property {any} unitFilters
 * @property {any} tagFilters
 * @property {any} taglessFilter
 * @property {any} positionFilters
 * @property {any} resultComponents
 *
 * @property {import('./continuous-analysis-reducer').ContinuousAnalysisPositionStates} continuousAnalysispositionStates
 * @property {any} liveUpdate
 * @property {any} ongoingAnalysis
 *
 * @property {any} currentInstance
 * @property {any} selectedUnit
 */
export const rootReducer = combineReducers({
   sites: sitesReducer,
   device: deviceReducer,
   timeZone: timeZoneReducer,
   dialog: dialogReducer,

   /* USER */
   auth: authReducer,
   user: userReducer,
   userToolConfig: userToolConfigReducer,

   /* SITE AND TOOLS */
   tools: toolsReducer,
   toolboxes: toolboxesReducer,
   units: unitsReducer,
   positions: positionsReducer,
   tags: tagsReducer,
   selectedSite: siteReducer,
   selectedTool: toolReducer,

   /* DATA */
   analyses: analysesReducer,
   selectedAnalyses: selectedAnalysesReducer,
   timeframe: timeframeReducer,
   liveUpdatePaused: liveUpdatePausedReducer,
   timeframeZoomSelection: timeframeZoomSelectionReducer,
   timeframeSelection: timeframeSelectionReducer,
   liveUpdateFollowRange: liveUpdateFollowRangeReducer,
   unitFilters: unitFiltersReducer,
   tagFilters: tagFiltersReducer,
   taglessFilter: taglessFilterReducer,
   positionFilters: positionFiltersReducer,
   resultComponents: resultComponentsReducer,


   /* SOCKET IO */
   continuousAnalysispositionStates: continuousAnalysisReducer,
   liveUpdate: liveUpdateReducer,
   ongoingAnalysis: ongoingAnalysisReducer,


   /* ONLY REACT-NATIVE HMI */
   currentInstance: CurrentInstanceReducer,
   selectedUnit: SelectedUnitReducer
})

// SELECTORS
export const selectToolsAnalyses = createSelector(
   (state) => state.analyses,
   (state) => state.selectedTool,
   (analyses, selectedTool) => analyses[selectedTool]
)
export const selectAnalysesObj = createSelector(state => state.analyses,
   (analyses) => {
      const allAnalyses = Object.values(analyses).flat()
      return allAnalyses.reduce((obj, analysis) => {
         obj[analysis.id] = analysis
         return obj
      }, {})
   }
)
export const selectTools = createSelector(state => state.tools, tools => Object.keys(tools).map(id => tools[id]))
export const selectToolboxes = createSelector(state => state.toolboxes, toolboxes => Object.keys(toolboxes).map(id => toolboxes[id]))
export const selectSites = createSelector(state => state.sites, sites => Object.keys(sites).map(id => sites[id]))
export const selectUnits = createSelector(state => state.units, units => Object.keys(units).map(id => units[id]))
export const selectUnitFilters = createSelector(state => state.unitFilters, filters => Object.keys(filters).map(id => filters[id]))
export const selectPositionFilters = createSelector(state => state.positionFilters, filters => Object.keys(filters).map(id => filters[id]))
export const selectTagFilters = createSelector(state => state.tagFilters, filters => Object.keys(filters).map(id => filters[id]))


export const selectToolsFilteredAnalyses = createSelector(
   (state) => state.analyses,
   (state) => state.unitFilters,
   (state) => state.tagFilters,
   (state) => state.taglessFilter,
   (state) => state.positionFilters,
   (state) => state.selectedTool,
   (analyses, unitFilters, tagFilters, taglessFilter, positionFilters, selectedTool) => {
      const filtered = []
      for (const analysis of analyses[selectedTool] || []) {
         // Filter by unit
         let include = !!(unitFilters[analysis.unit] && !unitFilters[analysis.unit].exclude) //  && (!analysis.position || (positionFilters[analysis.position] && !positionFilters[analysis.position].exclude))
         if (!include) continue

         // Filter by position
         for (const id of analysis.positions) {
            // If containes excluded position filter the analysis. Currently filtering is only used in continuous analysis where there is only one position per analysis.
            if (positionFilters[id] && positionFilters[id].exclude) {
               include = false
               break
            }
         }
         if (!include) continue


         // Filter by tag
         const includedTags = []
         Object.values(tagFilters).forEach(({ id, exclude }) => {
            if (!exclude) includedTags.push(id)
         })
         if (includedTags.length || (taglessFilter.enabled && !taglessFilter.exclude)) { // If all tags filters are disabled, do not apply filtering
            if (analysis.tags.length === 0) {
               // Filter by tagless
               if (taglessFilter.enabled && taglessFilter.exclude) continue
            } else {
               // Filter by tag
               let tagExists = false
               includedTags.forEach((filterId) => {
                  if (analysis.tags.includes(filterId)) tagExists = true // If at least one tag exists, include analysis
               })
               if (!tagExists) continue
            }
         }

         filtered.push(analysis)
      }
      return filtered
   }
)

export const selectFilteredToolsElementsPositionIdsThatHaveAnalysisData = createSelector(
   (state) => selectToolsAnalyses(state),
   (state) => selectToolsFilteredPositions(state),
   (state) => selectSelectedTool(state)?.resultModels?.[0],
   (analyses, positions, resultModel) => {
      if (!resultModel) return {}
      const elements = { ...resultModel.elements, ...resultModel?.combinedElements }

      /** @type { Object<string, string[]> } positionIds */
      const positionIdsWithData = {}
      for (const element in elements) {
         const { mean, standardDeviation } = elements[element]
         positionIdsWithData[element] = []
         // eslint-disable-next-line no-labels
         analysisLoop:
         for (const analysis of (analyses || [])) {
            for (const item of analysis.items) {
               const { result, position } = item
               if (!positions.find(p => p.id === position)) continue
               if (!result?.elements || !position) continue

               // Mean & standard deviation
               if (mean && standardDeviation) {
                  if (positionIdsWithData[element].includes(position) || result.elements[mean] == null) continue
               }
               // Other
               else {
                  if (positionIdsWithData[element].includes(position) || result.elements[element] == null) continue
               }

               positionIdsWithData[element].push(position)
               let hasAllPositionsData = true
               for (const posId of Object.keys(positions)) {
                  if (!positionIdsWithData[element].includes(posId)) {
                     hasAllPositionsData = false
                     break
                  }
               }
               // eslint-disable-next-line no-labels
               if (hasAllPositionsData) break analysisLoop
            }
         }
      }

      return positionIdsWithData
   }
)

export const selectToolsTags = createSelector(
   (state) => state.tools[state.selectedTool].tags,
   (state) => state.tags,
   (toolsTags, tags) => {
      const tagObjects = toolsTags.map((id) => tags[id])
      tagObjects.sort((a,b) => {
         if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
         return 1
      })
      return tagObjects
   }
)

export const selectToolsUnits = createSelector(
   (state) => state.units,
   (state) => state.selectedTool,
   (_units, tool) => {
      const units = Object.keys(_units).map((id) => _units[id])
      return (
         units.filter(unit => unit.tools.includes(tool))
      )
   }
)
export const selectToolsPositions = createSelector(
   (state) => state.tools[state.selectedTool].positions,
   (state) => state.positions,
   (toolsPositions, positions) => {
      const positionsArr = []
      toolsPositions.forEach(id => {
         if (positions[id]) positionsArr.push(positions[id])
      })
      positionsArr.sort((a,b) => {
         if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
         return 1
      })
      return positionsArr
   }
)
export const selectToolsFilteredPositions = createSelector(
   (state) => selectToolsPositions(state),
   (state) => state.positionFilters,
   (toolsPositions, positionFilters) => (
      toolsPositions.filter((p) => positionFilters[p.id]?.exclude !== true)
   )
)

export const selectSelectedTool = createSelector(
   (state) => state.selectedTool,
   (state) => state.tools,
   (selectedTool, tools) => tools[selectedTool]
)
export const selectSelectedSite = createSelector(
   (state) => state.selectedSite,
   (state) => state.sites,
   (selectedSite, sites) => {
      if (!selectedSite || !sites) return null
      return sites[selectedSite]
   }
)
